import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import PlainHeader from "components/ui/base/PlainHeader";
import { useTranslation } from "react-i18next";
import { Webinar as WebinarType, WebinarQueryResult } from "app-types/webinar";
import { Title } from "components/core/Typography/Title";
import { graphql } from "gatsby";
import { PageContainer } from "styles/global.style";
import { WebinarContainer } from "styles/webinars.style";
import Loop from "components/core/Loop";
import Webinar from "components/ui/base/Webinar";
import { Col, Row } from "styled-bootstrap-grid";

interface MissionPageProps {
  data: WebinarQueryResult;
  location: Location;
}

export default ({ data, location }: MissionPageProps) => {
  const webinars = data.webinars.nodes;
  const { t } = useTranslation();

  let webinarsByCategory: any = {};
  for (let idx in webinars) {
    const webinar = webinars[idx];
    const category = webinar.category;

    if (!Object.keys(webinarsByCategory).includes(category)) {
      webinarsByCategory[category] = [];
    }

    webinarsByCategory[category].push(webinar);
  }

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.webinars.seo.title")}
        description={t("pages.webinars.seo.description")}
        image={{
          relativePath: "meta/customerly-webinars.jpg",
          alt: "Customerly Webinars",
        }}
      />
      <PlainHeader
        title={t("pages.webinars.title")}
        description={t("pages.webinars.description")}
        maxWidth={800}
      />

      <PageContainer>
        <WebinarContainer>
          {Object.keys(webinarsByCategory).map((category: string) => (
            <React.Fragment key={category}>
              <Title>{category}</Title>
              <Row>
                <Loop items={webinarsByCategory[category]}>
                  {(webinar: WebinarType) => (
                    <Col key={webinar.slug} md={6} sm={12}>
                      <Webinar webinar={webinar} />
                    </Col>
                  )}
                </Loop>
              </Row>
            </React.Fragment>
          ))}
        </WebinarContainer>
      </PageContainer>
    </GlobalWrapper>
  );
};

export const webinarsPageQuery = graphql`
  query {
    webinars: allContentfulWebinar(
      sort: { fields: createdAt, order: DESC }
      filter: { node_locale: { eq: "en" } }
    ) {
      nodes {
        id
        slug
        title
        category
        shortDescription
        longDescription {
          childMarkdownRemark {
            html
          }
        }
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          alt: title
        }
        hostName
        hostRole
        hostImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        durationMinutes
      }
    }
  }
`;
